import { apiClient } from "./apiClient";
import { IRepsonseWrapper } from ".";
import { sharePoint } from "../assets/images";

const getContractAnalysis = async (documetUUID: string) => {
  const res = await apiClient.get<IRepsonseWrapper<any>>(`/contract-analysis/${documetUUID}`);
  return res;
};

const getTopics = async (projectId?: string) => {
  let url = "/topic/overview";
  if (projectId) {
    url += `?projectId=${projectId.toLowerCase()}`;
  }
  const res = await apiClient.get<IRepsonseWrapper<ITopic[]>>(url);
  return res;
};

const getProjects = async () => {
  const res = await apiClient.get(`/projects`);
  return res;
};

const deleteTopic = async (id: number) => {
  const res = await apiClient.delete<IRepsonseWrapper<ITopic>>(`/topic/${id}`);
  return res;
};

const createTopics = async (
  displayName: string,
  storage: string,
  projectId: string,
  createSourceContainer?: boolean,
  sourceName?: string,
  driverId?:string
) => {
  const query = createSourceContainer ? `?create_source_container=${createSourceContainer}` : "";

  
  const res = await apiClient.post<IRepsonseWrapper<ITopic>>(`topic${query}`, {
    sourceName,
    displayName,
    storage,
    projectId,
    driverId
  });

  return res;
};

const createNonDefaultTopics = async (
  displayName: string,
  projectId: string,
  folderPath: string
) => {
  
  const res = await apiClient.post<IRepsonseWrapper<ITopic>>(`topic/non-default`, {
    displayName,
    folderPath,
    projectId,
  });

  return res;
};


const getTopic = async (id: number) => {
  const res = await apiClient.get<IRepsonseWrapper<ProjectDetailResponse>>(`topic/${id}`);
  return res;
};

const getProjectsSettings = async (uuid?: string) => {
  const res = await apiClient.get(`project/setting/${uuid}`);
  return res;
};

const updateProjectIngestionSetting = async (
  id: string,
  reqObj: UpdateProjectIngestionSettingRequest
) => {
  const res = await apiClient.put<IRepsonseWrapper<ProjectDetailResponse>>(
    `projects/ingestion-setting/${id}`,
    reqObj
  );
  return res;
};

const updateProjectQuerySetting = async (id: string, reqObj: UpdateProjectQuerySettingRequest) => {
  const res = await apiClient.put<IRepsonseWrapper<ProjectDetailResponse>>(
    `projects/query-setting/${id}`,
    reqObj
  );
  return res;
};

const getProjectById = async (projectId: string) => {
  const res = await apiClient.get(`/projects/${projectId?.toLowerCase()}`);
  return res.data.data[0];
};

const getFoldersByProjectId = async (projectId: string) => {
  const res = await apiClient.get(`topic/${projectId}/non-processed`);
  return res.data;
};

export const TopicsService = {
  getTopics,
  deleteTopic,
  createTopics,
  getTopic,
  updateProjectIngestionSetting,
  updateProjectQuerySetting,
  getProjects,
  getContractAnalysis,
  getProjectsSettings,
  getProjectById,
  getFoldersByProjectId,
  createNonDefaultTopics
};
