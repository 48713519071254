import React, { useEffect, useState } from "react";
import { ProjectsService, TopicsService } from "../../api";
import { Button, Card, Tooltip, Table } from "antd";
import { EditOutlined, DeleteOutlined, LineOutlined } from "@ant-design/icons";
import "./project.scss";
import {
  PROJECT_TYPE,
  MODAL_TYPE,
  PAGE_URL,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  IProject,
  RootState,
  fileFolderUploadInitialState,
  openModal,
  updateProject,
  updateProjects,
  updateDeleteConfirmation,
  updateTopicId,
  updateProjectType,
  updateSelectedProject,
  closeModal
} from "../../store";
import { Loading } from "../../components";
import { ListDataIcon, TwoByTwoTileOption } from "../../assets/icons";
import { ColumnsType } from "antd/es/table";
import { useProject } from "../../hooks";

enum VIEW_STYLE {
  TILES,
  LIST,
}

const ProjectsContainer = () => {
  const [project, setProject] = useState<IProject[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [viewStyle, setViewStyle] = useState(VIEW_STYLE.TILES);
  const { selectedProjects } = useSelector((state: RootState) => state.fileFolderUpload);
  const { isDeleteConfirmed } = useSelector((state: RootState) => state.ui);
  const { fetchTopics } = useProject();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await ProjectsService.getProjects();
        const extractedData:any[] = res.data.data.map((item:any) => ({
          uuid: item.uuid,
          displayName: item.displayName,
          embeddingModel: item.embeddingModel,
          description: item.description,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
        }));
        dispatch(updateProjects(extractedData));
        setProject(res.data.data);
      } catch (error:any) {
        console.error("Error fetching projects:", error);
        openNotificationWithIcon("", error.response?.data?.message || "An error occurred", "error");
        setProject([]);
      } finally {
        setLoading(false);
      }
    };
  
    if (!selectedProjects || selectedProjects.uuid === "") {
      fetchData();
    }
  
    if (isDeleteConfirmed ) {
      const deleteProjects = async () => {
        try {
          await ProjectsService.deleteProjects(selectedProjects.uuid);
          dispatch(closeModal(MODAL_TYPE.DELETE_CONFIRMATION))
          dispatch(updateSelectedProject(fileFolderUploadInitialState.selectedProjects));

          openNotificationWithIcon("", "Project deleted successfully", "success");
        } catch (error:any) {
          openNotificationWithIcon("", error.response?.data?.message || "An error occurred", "error");
        } finally {
          
          dispatch(updateDeleteConfirmation(false));
        }
      };
      deleteProjects();
    }
  }, [selectedProjects, isDeleteConfirmed, dispatch]);
  

  const handleClick = async (item: any) => {
    dispatch(updateProject(item));
    setLoading(true);
    await fetchTopics(item.uuid);
    setLoading(false);
    if (item.type === PROJECT_TYPE.SINGLE_DOC_QA) {
      return navigate(`/projects/${item.uuid.toLowerCase()}/documents`);
    }
    return navigate(`/projects/${item.uuid.toLowerCase()}/ask-auto`);
  };

  const handleEdit = (item: IProject) => {
    dispatch(updateSelectedProject(item));
    dispatch(
      openModal({
        modalName: MODAL_TYPE.EDIT_PROJECTS,
        dataSource: "",
      })
    );
  };

  const handleDelete = (item: IProject) => {
    dispatch(updateSelectedProject(item));
    dispatch(
      openModal({
        modalName: MODAL_TYPE.DELETE_CONFIRMATION,
        dataSource: "",
      })
    );
  };  
  const sortedData = project
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .map((item) => ({
      key: item.uuid,
      ...item,
    }));
  const renderProjectsInTiles = () => (
    <div className="project-container">
      {sortedData.map((item, index) => (
        <Card key={index} className="project-card" onClick={() => handleClick(item)} actions={[]}>
          <div className="flex jcsb">
            <div className="title">{item.displayName}</div>
            <div className="flex gp link-color">
              <Tooltip title="Edit" key="edit">
                <EditOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(item);
                  }}
                  style={{ fontSize: "16px" }}
                />
              </Tooltip>
              <Tooltip title="Delete" key="delete">
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(item);
                  }}
                  style={{ fontSize: "16px" }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="description">{item.description || ""}</div>
          <div className="d-flex">
            <p className="pr-10">
              Created on:{" "}
              <span className="highlight">{getLocalDateTimeStringFromISO(item.createdAt)}</span>
            </p>
            <LineOutlined rotate={90} style={{ fontSize: "16px" }} />
            <p className="pl-10">
              Updated on:{" "}
              <span className="highlight">{getLocalDateTimeStringFromISO(item.updatedAt)}</span>
            </p>
          </div>
        </Card>
      ))}
    </div>
  );

  const renderProjectsInLists = () => {
    const getColumns: ColumnsType<IProject> = [
      {
        title: "Name",
        dataIndex: "displayName",
        key: "displayName",
        sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text) => <div className="project-description">{text}</div>,
      },
      {
        title: "Created On",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => getLocalDateTimeStringFromISO(text),
        sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      },
      {
        title: "Updated On",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (text) => getLocalDateTimeStringFromISO(text),
        sorter: (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      },
      {
        title: "Action",
        key: "action",
        render: (_, item) => (
          <div className="flex gp">
            <Tooltip title="Edit" key="edit">
              <EditOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(item);
                }}
                style={{ fontSize: "16px", color:"var(--linkColor)" }}
              />
            </Tooltip>
            <Tooltip title="Delete" key="delete">
              <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(item);
                }}
                style={{ fontSize: "16px", color:"var(--linkColor)" }}
              />
            </Tooltip>
          </div>
        ),
      },
    ];

    const data = project.map((item) => ({
      key: item.uuid,
      ...item,
    }));

    return (
      <div className="project-list basic-detail-content">
        <Table
          columns={getColumns}
          dataSource={data}
          pagination={false}
          onRow={(record) => ({
            onClick: () => handleClick(record),
          })}
          rowClassName={"cursor-pointer"}
        />
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <div className="loading">
          <Loading className="" />
        </div>
      ) : (
        <div>
          <div className="flex m-b jcsb">
            <div className="flex gp">
              <TwoByTwoTileOption
                className={`view-control cursor-pointer ${
                  viewStyle === VIEW_STYLE.TILES ? "active" : ""
                } `}
                onClick={() => setViewStyle(VIEW_STYLE.TILES)}
              />
              <ListDataIcon
                className={`view-control cursor-pointer ${
                  viewStyle === VIEW_STYLE.LIST ? "active" : ""
                } `}
                onClick={() => setViewStyle(VIEW_STYLE.LIST)}
              />
            </div>
            <Button
              className="fill"
              onClick={() => {
                navigate(PAGE_URL.CREATE_PROJECTS);
              }}
            >
              Create New Project
            </Button>
          </div>
          <div>
            {viewStyle === VIEW_STYLE.TILES ? renderProjectsInTiles() : renderProjectsInLists()}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectsContainer;
