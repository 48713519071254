import React, { useCallback, useEffect, useState } from "react";
import { FileFolderUpload, UploadedFilesFolders } from "./dataset-upload-documents";
import { FileFolder, uploadFileFolderService } from "../../../api";
import { useDispatch, useSelector } from "react-redux";

import {
  IDirectoryPath,
  RootState,
  updateCanFetchFiles,
  updateDirectoryPath,
  updateTopicId,
  updateStorage,
} from "../../../store";
import {
  STATUS_CODE,
  STORAGE_TYPES,
  getOffsetFromPageAndPageSize,
  openNotificationWithIcon,
} from "../../../utils";
import { useFetchFileStatus, usePagination } from "../../../hooks";

export const DatasetUploadDocuments = () => {
  const dispatch = useDispatch();
  const [directoryPath, setDirectoryPath] = useState<IDirectoryPath>([]);
  const { canFetchFiles, continuationToken,project} = useSelector(
    (state: RootState) => state.fileFolderUpload
  );
  const [filesList, setFilesList] = useState<FileFolder[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({
    id: -1,
    value: "",
  } as IDropdownOption);
  const storage = useSelector((state: RootState) => state.fileFolderUpload.project.storage)
  const { onPaginationChange, page, pageSize } = usePagination();
  const { topicId } = useSelector((state: RootState) => state.fileFolderUpload);

  const handleProjectChange = useCallback((e: string, option: IDropdownOption, topics: ITopic[]) => {
    const storageType = topics.find(item => item.displayName === option.value)?.storage;
    const path=topics.find(item => item.displayName === option.value)?.folderPath
    setSelectedTopic({...option,folderPath:path});
    dispatch(updateStorage(option.storage))
    dispatch(updateTopicId(option.id));
    dispatch(updateDirectoryPath([]));
    setDirectoryPath([]);
    setFilesList([]);
    onPaginationChange(1, pageSize);
    dispatch(updateCanFetchFiles(true));
  }, [dispatch, onPaginationChange, pageSize]);

  const [totalFiles, setTotalFiles] = useState(0);
  const [isViewFilesFoldersAuthorized, setIsViewFilesFoldersAuthorized] = useState(true);
  const { isStatusLoading, statuses, selectedStatus, fetchStatus, handleStatusChange } =
    useFetchFileStatus({
      setIsViewFilesFoldersAuthorized,
      setFilesList,
      onPaginationChange,
      pageSize,
    });

  useEffect(() => {
    dispatch(updateDirectoryPath(directoryPath));
    dispatch(updateCanFetchFiles(true));
  }, [directoryPath, dispatch]);


  useEffect(() => {
    const fetchFilesAndFolder = async () => {
      try {
        setIsLoading(true);
        // console.log(directoryPath)
        const lastDirectoryPath = directoryPath.length === 0 ? '' : directoryPath[directoryPath.length - 1]?.path;
        const sharepointPath = directoryPath.length === 0 ? selectedTopic.folderPath : lastDirectoryPath;
        const { data } = await uploadFileFolderService.getFilesAndFolder(
          topicId,
          storage === STORAGE_TYPES.SHAREPOINT ? sharepointPath : (directoryPath[directoryPath.length - 1]?.path || ''),
          pageSize,
          getOffsetFromPageAndPageSize(page, pageSize),
          selectedStatus.id === -1 ? "" : selectedStatus.value
        );
        setTotalFiles(data.pagination?.total || 0);
        // setFilesList((prev) => [...prev, ...data.data]);
        setFilesList(data.data);
        // setStorage(data.data[0].storage)
        dispatch(updateCanFetchFiles(false));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsViewFilesFoldersAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setIsLoading(false);
      }
    };
    if (canFetchFiles && topicId !== -1) fetchFilesAndFolder();
  }, [
    canFetchFiles,
    directoryPath,
    dispatch,
    page,
    pageSize,
    topicId,
    selectedStatus.id,
  ]);

  return (
    <>
      {topicId === -1 ? <></> : <FileFolderUpload
        {...{
          directoryPath,
          selectedProject: selectedTopic,
          setFilesList,
          page,
          pageSize,
          onPaginationChange,
          storage,
        }}
      />}
      <UploadedFilesFolders
        isViewFilesFoldersAuthorized={isViewFilesFoldersAuthorized}
        directoryPath={directoryPath}
        list={filesList}
        setDirectoryPath={setDirectoryPath}
        isLoading={isLoading}
        topicId={topicId}
        setIsLoading={setIsLoading}
        {...{
          selectedTopic: selectedTopic,
          continuationToken,
          setFilesList,
          setIsViewFilesFoldersAuthorized,
          isStatusLoading,
          statuses,
          selectedStatus,
          fetchStatus,
          handleStatusChange,
          totalFiles,
          page,
          pageSize,
          onPaginationChange,
          project:project.type,
          setSelectedTopic: setSelectedTopic,
          handleProjectChange,
        }}
      />
    </>
  );
};
